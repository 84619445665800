@import "~@edx/brand/paragon/variables";
@import "~@openedx/paragon/scss/core/core";
@import "~@edx/brand/paragon/overrides";

.popover.overlay-help-popover {
  z-index: 4000;
  margin-right: map-get($spacers, 1) !important;
  .help-popover-option {
    margin-bottom: map-get($spacers, 1);
  }
}


.rubric-card {
  width: 320px !important;
  height: fit-content;
  max-height: 100%;
  margin-left: map-get($spacers, 3);
  position: sticky !important;
  top: map-get($spacers, 1) * -1;

  .rubric-header {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.3) !important;
    display: flex;
    justify-content: center;
    padding: map-get($spacers, 3);
  }

  .rubric-body {
    overflow-y: hide;
    padding: map-get($spacers, 3);
  }

  .rubric-footer {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.3) !important;
    display: flex;
    justify-content: center;
    padding: map-get($spacers, 3);
  }

  button.pgn__stateful-btn.pgn__stateful-btn-state-pending {
    opacity: .4 !important;
  }
}

@include media-breakpoint-down(sm) {
  .rubric-card {
    margin-left: 0 !important;
  } 
}
