@import "@openedx/paragon/scss/core/core";

.assessment-content-layout {
  & > div.content-body {
    height: 100%;
    .row {
      height: 100%;
    }
  }
  width: fit-content;
  margin: auto;
  height: 100%;

  .content-wrapper {
    max-width: $max-width-lg;
  }
}

.ora-tinymce .tox-tinymce {
  background-color: $white;
}

@include media-breakpoint-down(sm) {
  .assessment-content-layout {
    .content-wrapper {
      width: 100%;
    }
  }
}

