@import "@openedx/paragon/scss/core/core";

#ora-xblock-view {
  max-width: 1024px;
  @include media-breakpoint-down(md) {
    padding-left: 40px;
    padding-right: 40px;
  }

  .rubric-card {
    width: 100% !important;
    margin-left: 0;
  }
}
