@import '@openedx/paragon/scss/core/core';

.ora-progress-nav-group {
  max-width: $max-width-lg;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  .ora-progress-divider {
    position: absolute;
    max-width: $max-width-lg;
    width: 100%;
  }
  .ora-progress-nav {
    background-color: white;
    z-index: 5;
    font-size: 1.125rem;
    &.is-active {
      border-bottom: 2px solid black;
    }
  }
  .nav-icon {
    display: inline-block;
    margin-inline-end: 0.5rem;
    margin-left: -0.25rem;
    svg {
      display: inline-block;
    }
  }
  .number-icon {
    background-color: black;
    color: white;
    border-radius: 100%;
  }
}

@mixin navbar-collapse-definition {
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 0;

  .ora-progress-divider {
    display: none;
  }

  .ora-progress-nav {
    width: 100%;
    &.is-active {
      border: none;
      background-color: $light;
    }
  }
  .nav-icon {
    margin-left: map-get($spacers, 3);
  }
}

@include media-breakpoint-down(md) {
  .navbar-expand-lg .ora-progress-nav-group {
    @include navbar-collapse-definition;
  }
}

@include media-breakpoint-down(sm) {
  .navbar-expand-md .ora-progress-nav-group {
    @include navbar-collapse-definition;
  }
}
