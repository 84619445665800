.ora-status-alert {
  span svg {
    display: inline-block;
  }

  .btn {
    white-space: nowrap;
    width: auto !important;
  }
}
