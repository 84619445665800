@import "@edx/brand/paragon/fonts.scss";
@import "@edx/brand/paragon/variables.scss";
@import "@openedx/paragon/scss/core/core.scss";
@import "@edx/brand/paragon/overrides.scss";

#root {
  display: flex;
  flex-direction: column;

  main {
    flex-grow: 1;
  }
}

.h-screen {
  height: 100vh;
}

.w-screen {
  width: 100vw;
}

.spinner-md {
  height: 100px;
  width: 100px;
}

.gap-0 {
  gap: map-get($spacers, 0);
}

.gap-1 {
  gap: map-get($spacers, 1);
}

.gap-2 {
  gap: map-get($spacers, 2);
}

.gap-3 {
  gap: map-get($spacers, 3);
}

.gap-4 {
  gap: map-get($spacers, 4);
} 
.ora-icon svg {
  display: inline-block !important;
}

.app-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  scale: 3;
}